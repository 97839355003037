.App-header {
  text-align: center;
  background-color: #222;
  padding: 10px;
  color: white;
}

#top-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

#top-bar header {
  font-size: 1.15em;
}

#top-bar header i {
  font-size: 1.2em;
}

#top-bar .header {
  flex: 1;
}

.top-bar__filter {
  align-self: flex-start;
  min-width: 250px;
  margin: 0 20px 0 auto !important;
}

.top-bar__action {
  margin-left: 20px;
  align-self: flex-end;
}

/* default is too dark */
body.dimmed .ui.dimmer {
  background-color: rgba(0, 0, 0, 0.65);
}
