.login__wrapper {
  position: absolute;
  width: 320px;
   top: 55%;
  left: 50%;
   transform: translateX(-50%) translateY(-50%);
}

.login__wrapper img.logo {
  margin-top: -70px;
}
